import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

import { WhosOnlineContext } from '../../context/whosOnline-context';

import styles from './AudienceCount.module.css';

const AudienceCount = () => {

  const [list, setList] = useState([]);
    const OnlineList = useContext(WhosOnlineContext).usersConnected;

    useEffect(() => {
      setList(OnlineList);
    }, [OnlineList])
  
  return (
    <div id={styles.wrapper}>
      <FontAwesomeIcon icon={faUsers} />
      <h1 className={styles.number}>{list.length}</h1>
    </div>
  );
};

export default AudienceCount;
