import React from 'react';

import styles from './LoadingSpinnerDark.module.css';

const LoadingSpinnerCenter = props => {
  return (
    <div className={styles.loading}>
      <div className={styles.loadingContiner}>
        <span className={`${styles.loader} ${props.loaderThemeDark && styles.black}`}></span>
        <p className={styles.loadingMessage}>{`${
          props.message || 'Please wait...'
        }`}</p>
      </div>{' '}
    </div>
  );
};

export default LoadingSpinnerCenter;

