import React from 'react';

import './Card.css';

const Card = props => {

    return (
        <div className={`card ${props.className}`} style={props.style}>
          {props.title ?  <div className={"card-header"}><h3>{props.title}</h3></div> : null } 
         {props.children}
      </div>
    );
};


export default Card;