import React from 'react'
import background from "../../../img/flags.png"

import styles from './Flag.module.css';

const Flag = ({code}) => {
    return (
        <div className={styles.regionFlag}>
            <i style={{ backgroundImage: `url(${background})` }} className={`${styles.flag} ${styles.regionSelFlag} ${code}`}></i>
        </div>
    )
}

export default Flag
