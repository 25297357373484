import { faBullhorn, faColumns, faComments, faUsers, faTachometerAlt, faCog, faArrowAltCircleLeft, faGlobe, faVideo, faPoll, faListAlt, faChalkboardUser} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { NavLink } from 'react-router-dom'; 
import { useLocation } from 'react-router-dom';
import TooltipBasic from '../UIElements/tooltip/TooltipBasic';


import './mainNav.css';

 const MainNav = () => {

    const location = useLocation()
    const url = location.pathname;

    return (
        <div className="main-nav__wrapper">
           { url === '/' ? 
           <ul className="nav-links">
                <li>
                    <NavLink to="/">Events</NavLink>
                </li>
            </ul>
            :
            <ul className="nav-links">
                <li>
                    <NavLink to={`/dashboard`}>
                        <TooltipBasic label='Dashboard'>
                            <FontAwesomeIcon icon={faColumns}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/announcements`}>
                        <TooltipBasic label='Announcements'>
                            <FontAwesomeIcon icon={faBullhorn}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/agenda`}>
                        <TooltipBasic label='Agenda'>
                            <FontAwesomeIcon icon={faListAlt}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/qa-sessions`}>
                        <TooltipBasic label='Q&A Sessions'>
                            <FontAwesomeIcon icon={faComments}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/polling`}>
                        <TooltipBasic label='Polling'>
                            <FontAwesomeIcon icon={faPoll}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink to={`/breakout-rooms`}>
                        <TooltipBasic label='Breakout Rooms'>
                        <FontAwesomeIcon icon={faChalkboardUser} />
                        </TooltipBasic>
                    </NavLink>
                </li> */}
                <li>
                    <NavLink to={`/attendees`}>
                        <TooltipBasic label='Attendees'>
                            <FontAwesomeIcon icon={faUsers}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/stats`}>
                        <TooltipBasic label='Stats'>
                            <FontAwesomeIcon icon={faTachometerAlt}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
            {/* <li>
                <NavLink to={`/languages`}>
                    <TooltipBasic label='Languages'>
                        <FontAwesomeIcon icon={faGlobe}/>
                    </TooltipBasic>
                </NavLink>
            </li> */}
                <li>
                    <NavLink to={`/streams`}>
                        <TooltipBasic label='Streams'>
                            <FontAwesomeIcon icon={faVideo}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/settings`}>
                        <TooltipBasic label='Settings'>
                            <FontAwesomeIcon icon={faCog}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
            </ul>
            }
            { url !== '/' &&
           <ul className="nav-links">
                <li>
                    <NavLink to="/">
                        <TooltipBasic label='My Events'>
                            <FontAwesomeIcon icon={faArrowAltCircleLeft}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
            </ul>}
        </div>
    )
}


export default MainNav;