import React, { useEffect, useState, useContext } from 'react';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';

import Input from '../FormElements/Input';
import Select from '../FormElements/Select';
import Button from '../UIElements/Buttons/Button';
import LoadingSpinnerCenter from '../UIElements/Loading/LoadingSpinnerDark';
import ErrorModal from '../UIElements/Modal/ErrorModal';

import './CreateEventForm.css';

const CreateEventForm = ({ success }) => {
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_PUBLIC_URL } = process.env;
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const onSubmit = async event => {
    event.preventDefault();
    clearError();
    console.log(event.target.uri.value);
    //do some validation checks here.. for event create data

    try {
      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/event/create`,
        'POST',
        JSON.stringify({
          title: event.target.title.value,
          subTitle: event.target.subTitle.value,
          uri: event.target.uri.value,
          supportEmail: event.target.supportEmail.value,
          date: event.target.date.value,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      console.log(response);
      success();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="create-event-form-wrapper">
      <ErrorModal error={error} onClear={clearError} />
      {!isLoading && (
        <form onSubmit={onSubmit}>
          <Input
            id="title"
            name="title"
            type="text"
            placeholder="required"
            groupFront="Event Title:"
          />
          <Input
            id="subTitle"
            name="subTitle"
            type="text"
            placeholder="optional"
            groupFront="Event Sub Title:"
          />
          <Input
            id="supportEmail"
            name="supportEmail"
            type="email"
            placeholder="required"
            groupFront="Support Email:"
          />
          <label className="event-uri-label">
            This Cannot be changed! must be lowercase without spaces
          </label>
          <Input
            id="uri"
            name="uri"
            type="text"
            placeholder="Event URI"
            groupFront={`${REACT_APP_PUBLIC_URL}/`}
          />
          <Input id="datetime" name="date" type="date" groupFront="Event Date:" />
          <div className="create-event-actions">
            <Button title="create" className="button-secondary" />
          </div>
        </form>
      )}
      {isLoading && <LoadingSpinnerCenter />}
    </div>
  );
};

export default CreateEventForm;
