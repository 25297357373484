import React from 'react';

import styles from './TooltipBasic.module.css'

const TooltipBasic = (props) => {
    return ( 
        <div className={styles.tooltip}>
            {props.children}
            <span className={styles.tooltiptext}>{props.label}</span>
        </div>
    )
}

export default TooltipBasic;
