import { useEffect, useContext } from 'react';
import { AuthContext } from '../context/auth-context';
import { SocketContext } from '../context/socket';

export const useCheckSocket = () => {

  const {userId, email, isLoggedIn} = useContext(AuthContext);
  const socket = useContext(SocketContext);

// check connection status
useEffect(() => {
    if (!socket.id && isLoggedIn) {
      socket.auth = { 
        userId,
        email,
        admin: true
      };
      console.log('socket');
      console.log(socket.auth);
      socket.connect();
    }
}, [socket, userId, email, isLoggedIn]);
  
};


