import React from 'react';

import CreateEventForm from '../../shared/components/Forms/CreateEventForm';
import ContentContainer from '../../shared/components/UIElements/ContentContainer';

import './CreateEvent.css'

const CreateEvent = ({success}) => {

    return (
        <section id='create-event'>
            <ContentContainer title='Create an event'>
                <CreateEventForm success={success} />
            </ContentContainer>
        </section>
    )
}

export default CreateEvent;