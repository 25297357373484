import React, {Fragment} from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import Button from '../Buttons/Button';
import styles from './modal.module.css';

const backdrop = {
  position: 'fixed',
  zIndex: 999,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,.8)',
};

const modelStyle = {
  zIndex: 1000,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center'
};

const modelInnerStyle = {
  position: 'relative',
  background: 'rgba(255, 255, 255, 0.85)',
  backdropFilter:' blur(20px)',
  borderRadius: 24,
  minWidth: 250,
  maxWidth:'90%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop:200,
  color: '#333',
  fontSize:'1.8rem',
  textAlign: 'center',
  boxShadow: '0 6px 16px rgba(0,0,0,0.7)'
}

const Modal = ({ show, children, onCancel, hideButton, btnClassName, modalClassName }) => {
  return (
    <AnimatePresence>
      {show && (
        <Fragment>
          <motion.div
            key={0}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={backdrop}
            
          ></motion.div>

          <motion.div
            style={modelStyle}
            
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              style={modelInnerStyle}
              className={`${styles.modal} ${modalClassName}`}
              initial={{ y: 250 }}
              animate={{ y: 0 }}
              exit={{ y: 150 }}
            >

              {children}

              {!hideButton && <Button className={`${btnClassName}`} onClick={onCancel} title={'close'} />}
            </motion.div>
          </motion.div>
        </Fragment>
      )}
    </AnimatePresence>
  );
};

export default Modal;
