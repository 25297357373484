import { useState, useCallback, useRef, useEffect, useContext} from 'react';

import { AuthContext } from '../context/auth-context';

export const useHttpClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const activeHttpRequests = useRef([]);

  const auth = useContext(AuthContext);

  const sendRequest = useCallback( async (
    url,
    method = 'GET',
    body = null,
    headers = {
      Authorization: 'Bearer '+ auth.token
    }
  ) => {
    setIsLoading(true);

    const httpAbortCtrl = new AbortController();
    activeHttpRequests.current.push(httpAbortCtrl);

    try {
      const response = await fetch(url, {
          method,
          body,
          headers,
          signal: httpAbortCtrl.signal
      });

      const responseData = await response.json();
      
      activeHttpRequests.current = activeHttpRequests.current.filter(
        reqCtrl => reqCtrl !== httpAbortCtrl 
      );

      if (!response.ok) {
        throw new Error(responseData.message);
      }
      setIsLoading(false);
      return responseData;

    } catch (err) {
      
      if (err.message === 'Failed to fetch') {
        setError((`Connection to server failed! \n If this persists contact support.`));
      } else {
        setError(err.message);
      }
     
      setIsLoading(false);
      console.log(err.message);
      throw err;
    }
    
  }, [auth.token]);

const clearError = () => {
    setError(null);
}

useEffect(() => {
    return () => {
        activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort());
    };
}, []);

  return {isLoading, error, sendRequest, clearError };
};
