import React, {useState, useEffect, useContext}  from 'react';
import { useNavigate } from "react-router-dom";

import { EventContext } from '../../shared/context/event-context';
import SubMenu from '../../shared/components/nav/subMenu';
import EventList from '../components/EventList';
import CreateEvent from '../components/CreateEvent';

const subMenu = [
  {
    id: 0,
    title: 'My Events'
  },
  {
    id: 1,
    title: 'Create Event'
  }
]

const Events = ({setTitle, setEventData}) => {
 
  let navigation = useNavigate();
  const { createEvent, clearEvent } = useContext(EventContext);
  const [showEventList, setShowEventList] = useState(true);
  const [showAddEvent, setShowAddEvent] = useState(false);

  useEffect(() => {
    clearEvent();
  }, [])

  useEffect(() => {
    setTitle('Events');
  }, [])

  const selectEventHandler = eventData => {
    console.log(eventData);
    if (eventData) {
      createEvent(
        eventData.id,
        eventData.title,
        eventData.subTitle,
        eventData.uri,
        eventData.date,
        eventData.open,
        eventData.supportEmail
      );
      navigation(`/dashboard`);
    }
  }

  const subMenuStateHandler = (id) => {
    console.log(id);
    
    switch (id) {
      case 0:
        setShowAddEvent(false);
        setShowEventList(true);        
        break;
      case 1:
        setShowAddEvent(true);
        setShowEventList(false);        
        break;
    
      default:
        break;
    }
  }
  
  return (
    <div className="page__wrapper">
    <SubMenu items={subMenu} click={subMenuStateHandler} />
    <div className="page-content clear-header">
      {showEventList && <EventList selectedEvent={selectEventHandler} createEvent={()=>subMenuStateHandler(1)}/>}
      {showAddEvent && <CreateEvent success={()=>subMenuStateHandler(0)} />}
  
    </div>
  </div>
  );
};

export default Events;
