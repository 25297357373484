import React from 'react';
import LoadingDots from './LoadingDots';

import styles from './SqaureCard.module.css';

const SqaureCard = props => {

    return (
        <div className={`${styles.sqaureCard} ${props.className}`} style={props.style} onClick={props.onClick}>
          <div className={styles.iconWrapper}>
                {props.children}
              </div>
              <div className={styles.contentWrapper}>
                 {props.isLoading ? <div className={styles.dotsWrapper}><LoadingDots className={styles.dots}/><span>updating..</span></div> : <h1 className={styles.value}>{props.value}</h1>}
                  <h2 className={styles.title}>{props.label}</h2>
              </div>
        
      </div>
    );
};


export default SqaureCard;