import React from 'react'

import './subMenu.css';

const SubMenu = props => {


    return (
        <div className='sub-menu__wrapper'>
            <ul className='sub-menu-list'>
                {props.items.map(i => (
                    <li key={i.id} className='sub-menu-item'><button className='sub-menu-item-btn' onClick={() => {props.click(i.id)}} >{i.title}</button></li>
                ))}
            </ul>
        </div>
    )
}

export default SubMenu;