import React, { useState, useEffect } from 'react';
import moment from 'moment';

import styles from './WOItem.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChrome,
  faEdge,
  faFirefoxBrowser,
  faOpera,
  faSafari,
} from '@fortawesome/free-brands-svg-icons';
import { faDesktop, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import Flag from '../UIElements/Flag';

const WOItem = ({ item }) => {
  console.log(item);

  const [countryCode, setCounrtCode] = useState('gb');
  const [browser, setBrowser] = useState('?');
  const [deviceType, setDeviceType] = useState('?');
  const [ip, setIp] = useState('0:0:0:0');
  const [icon, setIcon] = useState();
  const [deviceIcon, setDeviceIcon] = useState();

  useEffect(() => {
    if (item.user) {
      if (item.user.stats.length > 0) {
        item.user.stats[0].browserName && setBrowser(item.user.stats[0].browserName);
        item.user.stats[0].platform && setDeviceType(item.user.stats[0].platform);
        item.user.stats[0].countryCode &&  setCounrtCode(item.user.stats[0].countryCode.toLowerCase());
      }
    }

    if (item.ip) {
      setIp(item.ip);
    }
  }, [item]);

  useEffect(() => {
    browserIcon();
    deviceIconHandler();
  }, [browser, deviceIcon]);

  const browserIcon = () => {
    if (browser) {
      switch (browser) {
        case 'Firefox':
          setIcon(faFirefoxBrowser);
          break;

        case 'Chrome':
          setIcon(faChrome);
          break;

        case 'Safari':
          setIcon(faSafari);
          break;

        case 'Microsoft Edge':
          setIcon(faEdge);
          break;

        case 'Opera':
          setIcon(faOpera);
          break;

        default:
          break;
      }
    }
  };

  const deviceIconHandler = () => {
    if (deviceType) {
      switch (deviceType) {
        case 'desktop':
          setDeviceIcon(faDesktop);
          break;

        case 'mobile':
          setDeviceIcon(faMobileAlt);
          break;

        default:
          break;
      }
    }
  };

  return (
    <div className={styles.listItem}>
      <div className={styles.userInfoContainer}>
        <div className={styles.titleContainer}>
          {countryCode && <Flag code={countryCode} />}
          <h1 className={styles.username}>{item.user.name}</h1>
        </div>

        <h2 className={styles.email}>{item.user.email}</h2>
      </div>

      <div className={styles.infoContainer}>
        <div className={styles.iconsContainer}>
          <p className={styles.deviceIcons}>
            {deviceIcon && <FontAwesomeIcon icon={deviceIcon} />}{' '}
          </p>
          <p className={styles.icons}>
            {icon && <FontAwesomeIcon icon={icon} />}
          </p>
        </div>

        <p>{moment(item.updatedAt).format('h:mm:ss a')}</p>
      </div>
    </div>
  );
};

export default WOItem;
