import React from 'react';

import './LoadingDots.css'

const LoadingDots = ({className}) => {
    return (
        <div>
            <div className={`lds-ellipsis ${className}`}><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default LoadingDots;