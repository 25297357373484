import React, { createContext, useState } from 'react';

export const WhosOnlineContext = createContext({
  usersConnected: [],
  updateList: () => {}
});

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default props => {
  const [usersConnectedList, setUsersConnectedList] = useState([]);

  const updateList = list => {
    setUsersConnectedList(list);
  }

  return (
    <WhosOnlineContext.Provider value={{usersConnected: usersConnectedList, updateList: updateList}}>
      {props.children}
    </WhosOnlineContext.Provider>
  );
};