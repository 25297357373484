import React from 'react'
import { useNavigate } from 'react-router-dom';

import styles from './logo.module.css'

 const Logo = ({imgClassName, icon, white, mono}) => {
    return (
        <div className={styles.wrapper}>
             {white && icon && <img className={`${styles.logo} ${imgClassName} `} src="/assets/images/logo-icon-white.png" alt="logo"/>}
             {white && !icon && <img className={`${styles.logo} ${imgClassName} `} src="/assets/images/logo-white.png" alt="logo"/>}
             {!white && icon && <img className={`logo-img ${imgClassName} `} src="/assets/images/logo-icon.png" alt="logo"/>}
             {!white && !icon && <img className={`logo-img ${imgClassName} `} src="/assets/images/logo.png" alt="logo"/>}
        </div>
    )
}


export default Logo;