import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import WOItem from './WOItem';

import styles from './WOList.module.css';

const WOList = ({ list }) => {
  return (
    <div className={styles.mainList}>
      <AnimatePresence>
        {list.map(item => (
          <motion.div
            key={item.id}
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1 ,x: 0 }}
            exit={{ opacity: 0, x: -100 }}
          >
            <WOItem key={item.id} item={item} />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default WOList;
