import React, { useState, useEffect, useContext, Fragment } from 'react';

import { EventContext } from '../../context/event-context';
import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';

import ErrorModal from '../../components/UIElements/Modal/ErrorModal';
import AreYouSure from '../UIElements/AreYouSure';

import styles from './EventCheckbox.module.css';
import Modal from '../UIElements/Modal/Modal';

const EventCheckbox = () => {
  const { REACT_APP_API_URL } = process.env;
  const auth = useContext(AuthContext);
  const eventCtx = useContext(EventContext);

  const { error, sendRequest, clearError } = useHttpClient();
  const [toggle, setToggle] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [eid, setEid] = useState();

  useEffect(() => {
    setEid(eventCtx.eventId);
    eventCtx.eventId && getEventStatus(eventCtx.eventId);
  }, [eventCtx]);

  const getEventStatus = async id => {
    console.log(id);
    try {
      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/event/live-event-status-id`,
        'POST',
        JSON.stringify({
          eid: id,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      response.event && setToggle(response.event.open);
    } catch (err) {
      console.log(err);
    }
  };

  const updateStatus = async state => {
    try {
      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/event/toggle`,
        'PATCH',
        JSON.stringify({
          eid,
          isOpen: state,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      console.log(response);
      response.open && setToggle(response.open);
    } catch (err) {
      console.log(err);
    }
  };

  const changeState = () => {
    setToggle(toggle ? false : true);
    updateStatus(toggle ? false : true);
    setShowModal(false);
  };

  const toggleShowHandler = event => {
    setShowModal(true);
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal show={showModal} hideButton modalClassName={styles.aysModal}>
        <AreYouSure
          ok={changeState}
          cancel={() => setShowModal(false)}
          message={`You are about to ${toggle ? 'CLOSE' : 'OPEN'} the event.`}
        />
      </Modal>
      {eventCtx.eventId && (
        <span className={`${styles.switcher} `}>
          <input
            id="eventToggle"
            type="checkbox"
            checked={toggle}
            onChange={toggleShowHandler}
          />
          <label htmlFor="eventToggle"></label>
        </span>
      )}
    </Fragment>
  );
};

export default EventCheckbox;
