import React from 'react';

import styles from './AreYouSure.module.css';

const AreYouSure = ({ message, ok, cancel }) => {
  return (
    <div id={styles.areYouSure}>
      <div className={styles.header}>
        <h1 className={styles.title}>Are You Sure?</h1>
      </div>
      <div className={styles.body}>
        <p className={styles.text}>{message}</p>
      </div>
      <div className={styles.actions}>
          <button type='button' onClick={ok} className={styles.okBtn}>OK</button>
          <button type='button' onClick={cancel} className={styles.cancelBtn}>Cancel</button>
      </div>
    </div>
  );
};

export default AreYouSure;
