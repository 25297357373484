import React from 'react';

import './EventListItem.css'

const EventListItem = ({id, title, subTitle, selected}) => {

    return (
        <div className='event-list-item' onClick={()=>selected(id)}>
            <h1 className='event-list-item-h1' >{title}</h1>
            <h2 className='event-list-item-h2' >{subTitle}</h2>
        </div>
    )
}

export default EventListItem;