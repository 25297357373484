import React, { Fragment, useEffect, useContext, useState } from 'react';
import MapLarge from '../../shared/components/maps/MapLarge';

import MapSmall from '../../shared/components/maps/MapSmall';
import StreamPreview from '../../shared/components/Video/StreamPreview';
import WhosOnline from '../../shared/components/WhosOnline/WhosOnline';
import { EventContext } from '../../shared/context/event-context';

import Summery from '../components/Summery';

import './dashboard.css';
import styles from './dashboard.module.css';

const Dashboard = ({ setTitle , setEvid }) => {

  const { eventId } = useContext(EventContext);

  const [hideAll, setHideAll] = useState(false)
  
  useEffect(() => {
    setTitle('Dashboard')
  }, [])

  useEffect(() => {
   console.log('ID UPDATE');
   console.log(eventId);
   setEvid(eventId)
  }, [eventId])
 
  console.log('EVID: ');
  console.log(eventId);
  

  return (
    <>
      <div className={styles.pageWrapper}>
      <button style={{position: 'fixed', top:'60px', right: '10px', zIndex:'100'}} onClick={()=>setHideAll(state=>!state)} >toggle</button>
        <MapLarge/>
       {!hideAll && <div className={styles.summeryWrapper}>
          <Summery eventId={eventId}/>
        </div>}
       {!hideAll &&  <div className={styles.whosonlineWrapper}>
          <WhosOnline />
        </div>}
       
      </div>
    </>
  );
};

export default Dashboard;
