import React from 'react'
import OnlineUsers from './OnlineUsers';
import PollCount from './PollCount';
import QASessionCount from './QASessionCount';
import RegisteredUsers from './RegisteredUsers';

import styles from './Summery.module.css';

const Summery = ({eventId}) => {

    return (
        <div className={styles.wrapper}>
            <RegisteredUsers eventId={eventId}/>  
            <OnlineUsers eventId={eventId}/>   
            {/* <PollCount eventId={eventId}/> */}
            <QASessionCount eventId={eventId}/>   
        </div>
    )
}

export default Summery
