import React, {Suspense,Fragment, lazy, useState} from 'react';
import { SocketContext, socket } from './shared/context/socket';

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faTrashAlt,
  faKey,
  faCheck,
  faCalendarAlt,
  faPoundSign,
  faSignInAlt,
  faSignOutAlt,
  faEnvelope,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

import SideBar from './shared/components/nav/sidebar';
import Header from './shared/components/header/Header';
import Dashboard from './dashboard/pages/dashboard';
import Events from './event/pages/Events';
import Auth from './auth/pages/Auth';
import WhosOnlineContext from './shared/context/whosOnline-context';

import { useAuth } from './shared/hooks/auth-hook';
import { useEvent } from './shared/hooks/event-hook';
import { AuthContext } from './shared/context/auth-context';
import { EventContext } from './shared/context/event-context';
import LoadingSpinner from './shared/components/UIElements/Loading/LoadingSpinnerCenter';

import './App.css';

const Settings = lazy(() => import('./Settings/pages/Settings'));
const Agenda = lazy(() => import('./agenda/pages/Agenda'));
const Stats = lazy(() => import('./stats/pages/Stats'));
const Polls = lazy(() => import('./polls/pages/polls'));
const PollOutput = lazy(() => import('./polls/pages/PollOutput'));
const QASessions=lazy(()=> import('./qAndA/pages/qAndA'));
const Visitors=lazy(()=> import('./visitors/pages/Visitors'));
const Announce=lazy(()=> import('./Announcments/pages/Announce'));
const Forgot=lazy(()=> import('./auth/pages/ForgotPassword'));
const Reset=lazy(()=> import('./auth/pages/ResetPassword'));
const Langs=lazy(()=> import('./languages/pages/LangSettings'));
const Streams=lazy(()=> import('./liveStreams/pages/LiveStreams'));
const BreakoutRooms=lazy(()=> import('./breakoutRooms/pages/BreakoutRooms'));

library.add(
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  fab,
  faTrashAlt,
  faKey,
  faCheck,
  faSignOutAlt,
  faCalendarAlt,
  faSearch,
  faEnvelope,
  faPoundSign,
  faSignInAlt,
  faExclamationTriangle
);

function App() {

  const [pageTitle, setPageTitle] = useState('');
  const [evt, setEvt] = useState();

  const {
    userId,
    userFirstName,
    userLastName,
    userProfileImageUrl,
    email,
    token,
    login,
    logout,
  } = useAuth();

  const {
    eventId, 
    title, 
    subTitle,
    uri, 
    date, 
    open, 
    supportEmail,
    createEvent, 
    clearEvent 
  } = useEvent();

  const setTitle = title => {
    setPageTitle(title);
  }

  const setEvid = evid => {
    setEvt(evid);
    console.log(evid);
  }

  const updateEvent = event => {
    console.log(event);
    setEvt(()=>event);
  }
 
  let routes;

  if (!token) {
    routes = (
      <Routes>
        <Route path="/auth" exact element={<Auth />}/>
        <Route path="/forgot" exact element={ <Forgot />}/>
        <Route path="/reset/:token" element={ <Reset />}/>
        <Route path="*"  element={<Auth />}/>
      </Routes>
    );
  } else {
    routes = (
      <Fragment>
       
        <SocketContext.Provider value={socket}>
          <SideBar />
          <Header title={pageTitle} event={evt} />
          <Routes>
            <Route path="/" exact element={ <Events setTitle={setTitle}/>}/>
            <Route path="/dashboard" exact element={  <Dashboard setTitle={setTitle} setEvid={setEvid}/>}/>
            <Route path="/agenda" exact element={ <Agenda setTitle={setTitle} />}/>
            <Route path="/announcements" exact element={  <Announce setTitle={setTitle} />}/>
            <Route path="/qa-sessions" exact element={ <QASessions setTitle={setTitle} />}/>
            <Route path="/polling" exact element={ <Polls setTitle={setTitle} />}/>
            <Route path="/polling/poll-data/:pollId" exact element={ <PollOutput />}/>
            <Route path="/attendees" exact element={ <Visitors setTitle={setTitle} />}/>
            <Route path="/stats" exact element={ <Stats setTitle={setTitle} />}/>
            <Route path="/languages" exact element={  <Langs setTitle={setTitle} />}/>         
            <Route path="/streams" exact element={ <Streams setTitle={setTitle} />}/>
            <Route path="/settings" exact element={ <Settings setTitle={setTitle} />}/>
            <Route path="/breakout-rooms" exact element={ <BreakoutRooms setTitle={setTitle} />}/>
            <Route path="*"  element={  <Events setTitle={setTitle} updateEvent={()=>updateEvent} />}/>
          </Routes>
        </SocketContext.Provider>
      
      </Fragment>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        email: email,
        userProfileImageUrl : userProfileImageUrl,
        userFirstName: userFirstName,
        userLastName: userLastName,
        login: login,
        logout: logout,
      }}>
       <EventContext.Provider 
          value={{
            eventId: eventId,
            title: title,
            subTitle: subTitle,
            uri: uri, 
            date: date, 
            open: open, 
            supportEmail: supportEmail,
            createEvent: createEvent, 
            clearEvent: clearEvent,
        }}>
      <WhosOnlineContext>
      <Router>
        <main>
          <Suspense fallback={ <LoadingSpinner message='Downloading..' /> }>
            {routes}
          </Suspense>
        </main>
      </Router>
      </WhosOnlineContext>
      </EventContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;



