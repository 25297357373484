import React, {useState, useEffect, useContext} from 'react';
import ReactMapGL, { Marker } from 'react-map-gl'
import PulseDot from '../UIElements/PulseDot';
import { WhosOnlineContext } from '../../context/whosOnline-context';
import styles from './MapLarge.module.css';
import mapboxgl from 'mapbox-gl';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const defaultViewport = {
    width: "100%",
    height: "100%",
    latitude: 48.430472,
    longitude: 20.0,
    zoom: 3,
    bearing: 0,
    pitch: 0
}

const MapLarge = () => {

    const { REACT_APP_MAPBOX } = process.env;
    const [viewport, setViewport] = useState();
    const [locs, setLocs] = useState([]);
    const [isHidden, setIsHidden] = useState(true);
    const OnlineList = useContext(WhosOnlineContext).usersConnected;

    console.log(REACT_APP_MAPBOX);
    
    useEffect(() => {
        if (viewport && viewport.latitude === 48.430472 && viewport.longitude === 20.0 ) {
            setIsHidden(true);
        } else {
            setIsHidden(false);
        }
    }, [viewport])

    useEffect(() => {
        setViewport({
              width: "100%",
              height: "100%",
              latitude: 48.430472,
              longitude: 20.0,
              zoom: 3,
              bearing: 0,
              pitch: 0
          })
    },[])

    const viewportChnageHandler = vp => {
        setViewport(vp)
    }

    useEffect(() => {
        console.log(OnlineList);
        let conns = []
        if (OnlineList) {
            OnlineList.map(conn => {
               
                    console.log(conn.user.stats[0]);
                    conn.user.stats[0] && conns.push( conn.user.stats[0])
            
                
        })
        console.log(conns);
        setLocs(conns);
        }
        
    }, [OnlineList])

    const resetViewHandler = () => {
        setViewport(defaultViewport);
    }

    return (
        <div className={styles.mapContiner}>
            <ReactMapGL 
                {...viewport} 
                mapStyle='mapbox://styles/helogale/ckvss3u6h1c7y16qug3a04ln9'  
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX} 
                onViewportChange={vp => viewportChnageHandler(vp)}>
         {locs.map((loc, idx) => (
            loc.lat && <Marker key={idx} latitude={parseFloat(loc.lat)} longitude={parseFloat(loc.lng)}><PulseDot/></Marker>
           ))}
          
            </ReactMapGL>
            <button className={`${styles.resetBtn} ${isHidden && styles.hidden}`} type='button' onClick={resetViewHandler} >reset map view</button>
        </div>
    )
    
}

export default MapLarge
