import { createStore } from 'redux';

const eventIdReducer = (state = { eid: '-'}, action) => {
    //console.log('eventReducer was called with state', state, 'and action', action.eid)
    if (action.type === 'updateEventId') {
      console.log(state.eid);
      return { 
        eid: state.eid = action.id,
        };
    } 

    if (action.type === 'clearEventId') {
      return { 
        eid: state.eid = ''
        };
    }

    return state; 
  }
  
  const store = createStore(eventIdReducer);


  export default store;